<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Rename Mapping
      </h3>
    </div>

    <div class="modal-content px-8 py-4 bg-neutral-gray">
      <form
        :class="{ 'error' : form.errors.get('form'), 'error' : formFormHasErrors }"
        @submit.prevent="submit"
      >
        <div class="">
          <label
            for="mappingName"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            Name
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="layerName"
              ref="nameinput"
              v-model="form.name"
              type="text"
              autofocus
              class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            >
          </div>
        </div>
        <div class="mt-6 btn-group flex items-center justify-end">
          <button
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="btn primary"
            type="button"
            @click.prevent="submit"
          >
            Rename
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {

  props: ['item'],

  data () {
    return {
      form: new SparkForm({
        name: null
      }),
      formFormHasErrors: false
    }
  },

  computed: {

    readyToSubmit () {
      return this.form.name !== ''
    }
  },

  mounted () {
    console.log('Setting form name to value - ', this.item.name)
    this.form.name = this.item.name
  // this.$refs.nameinput.focus()
  },

  methods: {

    submit () {
      if (this.readyToSubmit) {
      // this.formFormHasErrors = false
      // this.form.startProcessing()
      // this.$store.dispatch('assets/newFolder', {
      //   project: this.project,
      //   parent: this.parent,
      //   name: this.form.name
      // })
      //   .then(result => {
      //     this.form.finishProcessing()
      //     this.$emit('close')
      //     alert.success('Folder created')
      //   })
      //   .catch(response => {
      //     alert.error('Failed to create folder')
      //     this.formFormHasErrors = true
      //     this.form.setErrors(response.data)
      //     this.form.finishProcessing()
      //   })
      }
    }

  }
}
</script>
